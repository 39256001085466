.app-navigation {
  background-color: #001645;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-navigation--logo {
  margin-right: 16px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  &.is-compact {
    margin-right: 8px;
  }
}

