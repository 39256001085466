@import "~config/theming/variables";

.app-header {
  background-color: #021063;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid #001645;
  text-align: center;

  &.is-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.app-header--content-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: $maxWidth;
  text-align: right;
}

.app-header--name {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  color: #3cdcc9; /* teal or no deal */
  font-family: "Gibson SemiBold";
}
