.navigation-tab {
  padding: 4px 20px;
  color: #afb0b0;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: "Interstate Regular Compressed";
  font-size: 24px;
  letter-spacing: 0.0625em;
  transition: color 0.2s;

  &.is-active {
    color: #f3f3f3;
  }

  &.is-compact {
    font-size: 16px;
    padding: 4px 6px;
  }
}
