.env-badge {
  font-size: 12px;
  font-family: "Roboto";
  border-radius: 2px;
  position: fixed;
  bottom: 8px;
  left: 16px;
  display: inline-block;
  line-height: 1;
  padding: 4px 8px;
  background-color: #23c2af;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
