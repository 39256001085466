@import "~config/theming/variables";

.app-layout {
  background-color: #001645;
  min-height: 100%;
}

.app-layout--page-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.app-layout--page {
  flex-basis: $maxWidth;
}

.app-layout--content {
  padding-bottom: 32px;
}
